export const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.80)",
  },
  content: {
    display: "grid",
    top: "40%",
    left: "50%",
    border: "none",
    // overflowY: "hidden",
    borderRadius: "8px",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "min-content",
    minHeight: "20rem",
    maxHeight: "70rem",
    backgroundColor: "transparent",
    width: "35%",
  },
};

export const customSideStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.80)",
  },
  content: {
    display: "grid",
    top: "50%",
    // bottom:"1%",
    left: "82.4%",
    border: "none",
    // overflowY: "hidden",
    borderRadius: "0px",
    right: "auto",
    // overflow: "hidden",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "100%",
    backgroundColor: "transparent",
    width: "55%",
    padding: "0",
    inset: "50% auto 40px 97.4%",
  },
};


